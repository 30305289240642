export default {
  ArcaneArmor: 'Чародейская броня',
  CursedChackle: 'Проклятые оковы',
  InvisibilityCloak: 'Накидка невидимости',
  SoulDrain: 'Похищение души',
  Teleport: 'Телепорт',
  TownPortal: 'Портал в город',
  Transformation: 'Превращение в животное',
  MagicMist: 'Магический туман',
  MassPowerDrain: 'Массовое вытягивание силы',
};
