export default {
  ImperialShooter: 'СТРЕЛОК. При входе в игру: вытащите из колоды другого Имперского стрелка себе в руку (если есть в колоде)',
  MasterShooter: 'СТРЕЛОК. ВОЗМЕЗДИЕ',
  YoungPriestess: 'СТРЕЛОК. СТАРТ ХОДА: Вылечивает 2 здоровье у случайного прилегающего союзного воина',
  WarriorOfLight: 'БОЕЦ. Имеет СТРЕМИТЕЛЬНОСТЬ',
  ValorKnight: 'БОЕЦ. Вылечивает 2 здоровья у союзных воинов в соседних клетках на линии при атаке в свой ход.',
  Bannerman: 'БОЕЦ. Когда входит в игру, прибавить союзному воину на поле + 2 к атаке на один его ответный удар или атаку',
  Defender: 'БОЕЦ. При любой атаке по вашему герою, снижает урон на 1, но сам получает урон заклинанием силой 1',
  RighteousFighter: 'БОЕЦ. Получает +1 к атаке по герою',
  KnightGuardian: 'БОЕЦ. Вражеские воины, при атаке в свой ход, не могут атаковать воинов в соседних клетках в линии от Рыцаря-защитника, но могут атаковать Рыцаря-защитника',
  ImperialGriffon: 'ЛЕТАЮЩИЙ',
  TemplarChampion: 'БОЕЦ. Когда входит в игру, вылечить 3 здоровья у союзного воина на поле. Имеет ПРОНИКАЮЩУЮ АТАКУ 2',
  MasterOfLight: 'БОЕЦ. Вместо хода: атаковать заклинанием силой 3 всех Вражеских воинов в шеренге в которой стоит (Атака светом)',
  Marksman: 'СТРЕЛОК. Вместо хода: атаковать ближайшего воина в шеренге заклинанием силой 3 (Сильный выстрел)',
  YouthFountain: 'Положить рядом с полем. Когда другой игрок выкладывает или перемещает своего воина на поле - вылечить 2 здоровья у вашего героя',
  RetaliationHammer: 'Отнять 2 здоровья у вражеского воина и переместить его на прилегающую клетку',
  TemplarChampCast: 'Выбрать воина для лечения',
  BannermanCast: 'Выбрать воина для увеличения атаки',
  Castle: 'Рыцари и ангелы полагающиеся на магию света',
  ZigfridHero: 'Удар молотом: атаковать силой 1 воина на поле боя и передвинуть его на соседнюю клетку в линии - 4д. Сила: взять карту из колоды за 1д.',
  AgielHero: 'Святое касание: вылечить у союзного воина 2 здоровья за 4д. Сила: взять карту из колоды за 1д.',
  factionInfo: 'Рыцари и ангелы полагающиеся на магию света и силу своих воинов',
  Morale: 'Получите 1 очко действия до конца вашего хода или вытянуть другую карту вместо этой',
  RetributionSword: 'АРТЕФАКТ. Вместо хода героя: воин получает + 1 к атаке на один удар за каждое ваше положительное заклиание на союзных воинах или на поле боя',
};
